import React from 'react';
import { Link } from 'react-router-dom';

import standardsImage from '../../../assets/images/home/2.jpg';
import brdaArrow from '../../../assets/icons/BRDA-STRELICA.png';

export default function Standards() {
    return (
        <div className='sectionWrapper'>
            <div className='sectionContainer'>
                <img src={standardsImage} alt='standardsImage' />
                <div className='sectionText'>
                    <h1>Visoki standardi gradnje i opremanja</h1>
                    Orijentirani smo prema energetski učinkovitoj gradnji, optimalnom omjeru kvalitete i sigurnosti, kao
                    i funkcionalnosti i iskoristivosti prostora.
                    <br />
                    <br />
                    Surađujemo isključivo s renomiranim, dugogodišnjim kooperantima kojima povjeravamo projektiranje,
                    izgradnju i nabavu vrhunskih materijala, što jamči kvalitetnu izvedbu, povjerenje i zadovoljstvo
                    naših klijenata. Objekte gradimo u skladu sa svim zakonima i propisima, što podrazumijeva toplinsku
                    i zvučnu izolaciju, zaštitu od požara i potresa, energetski certifikat i osigurana parking mjesta.
                    <br />
                    <br />
                    Prilikom projektiranja, vodimo računa da sve stambene jedinice imaju logičan raspored, maksimalno
                    iskorišten prostor i dovoljno prirodnog svjetla, a posebnu pozornost posvećujemo izboru završnih
                    materijala i opreme poput podova, vrata, prozora, keramike i sanitarija.
                    <br />
                    <br />
                    Vaše zadovoljstvo name je prioritet. stoga je naš stručni tim na raspolaganju svim klijentima u što
                    kraćem roku.
                </div>
            </div>
            <a href='https://brdaprojekt.hr/' target='_blank' rel='noopener' referrerPolicy='origin'>
                <img src={brdaArrow} alt='arrowLogo' className='linkImage' />
            </a>
        </div>
    );
}
