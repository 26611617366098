import React from 'react';
import { Link } from 'react-router-dom';

import { useHeader } from '../../../hooks/useHeader';
import { history } from '../../../../App';

import logo from '../../../../assets/icons/LOGO-FOOTER.png';

interface Props {
    aboutRef: React.MutableRefObject<any>;
    goToPageSection: (destinationPathname: string, offset: number, sectionRef?: any) => Promise<void>;
}

export default function DesktopHeader({ aboutRef, goToPageSection }: Props) {
    const {
        openHomeDropdown,
        setOpenHomeDropdown,
        openProjectsDropdown,
        setOpenProjectsDropdown,
        homeDropRef,
        projectsDropRef,
        finishedProjectsHovered,
        setFinishedProjectsHovered,
        contactHovered,
        setContactHovered,
    } = useHeader();

    const menuHovered = openHomeDropdown || openProjectsDropdown || finishedProjectsHovered || contactHovered;

    return (
        <>
            <div className='headerContent'>
                <img className='logo' src={logo} alt='logo' onClick={() => history.push('/')} />
                <div className='link'>
                    <Link
                        onMouseOver={() => {
                            setOpenHomeDropdown(true);
                            setOpenProjectsDropdown(false);
                            setFinishedProjectsHovered(false);
                            setContactHovered(false);
                        }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                        className={`link ${menuHovered && 'blured'} ${openHomeDropdown && 'activeLink'}`}
                        to='/'
                    >
                        POČETNA
                    </Link>
                    {openHomeDropdown && (
                        <div
                            id='aboutDropdown'
                            ref={homeDropRef}
                            onClick={() => {
                                goToPageSection('/', -200, aboutRef);
                                setOpenHomeDropdown(false);
                            }}
                        >
                            <p>O NAMA</p>
                        </div>
                    )}
                </div>
                <div className='link'>
                    <a
                        className={`link ${menuHovered && 'blured'} ${openProjectsDropdown && 'activeLink'}`}
                        onMouseOver={() => {
                            setOpenProjectsDropdown(true);
                            setOpenHomeDropdown(false);
                            setFinishedProjectsHovered(false);
                            setContactHovered(false);
                        }}
                    >
                        PROJEKTI U IZGRADNJI
                    </a>
                    {openProjectsDropdown && (
                        <div id='projectsDropdown' ref={projectsDropRef}>
                            <div>
                                <a
                                    className={`link `}
                                    href='https://brdaprojekt.hr/'
                                    target='_blank'
                                    rel='noopener'
                                    referrerPolicy='origin'
                                >
                                    BRDA
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className='link'>
                    <Link
                        className={`link ${menuHovered && 'blured'} ${finishedProjectsHovered && 'activeLink'}`}
                        to='/zavrseni_projekti'
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                        onMouseOver={() => {
                            setOpenProjectsDropdown(false);
                            setOpenHomeDropdown(false);
                            setFinishedProjectsHovered(true);
                            setContactHovered(false);
                        }}
                        onMouseLeave={() => setFinishedProjectsHovered(false)}
                    >
                        ZAVRŠENI PROJEKTI
                    </Link>
                </div>
                <div className='link'>
                    <Link
                        className={`link ${menuHovered && 'blured'} ${contactHovered && 'activeLink'}`}
                        to='/kontakt'
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                        onMouseOver={() => {
                            setOpenProjectsDropdown(false);
                            setOpenHomeDropdown(false);
                            setFinishedProjectsHovered(false);
                            setContactHovered(true);
                        }}
                        onMouseLeave={() => {
                            setContactHovered(false);
                        }}
                    >
                        KONTAKT
                    </Link>
                </div>
            </div>
            <hr className={`${menuHovered ? '' : 'hidden'}`} />
        </>
    );
}
